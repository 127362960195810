import './App.css';
import ProjectRoutes from './Router';

function App() {
  return (
    <ProjectRoutes/>
  )
  
}

export default App;
