import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const Auth = () => {


  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery().get("code");

  useState(() => {
    window.location.href = "trendapp://open-trend.com/?code="+query
  });

  return <div>Abrindo o app novamente...</div>;
};

export default Auth;
