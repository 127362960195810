import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { generateCode, confirmCode } from "../api"

const DeleteAccount = () => {
  const [email, setEmail] = useState("");
  useEffect(() => {

  }, [email])

  return (
    <><div style={{ padding: 12 }}>
      <p><b>Warning: </b>The following steps will delete all your account information from our servers and there will be no way to reverse this action. These data include: your last location, photos, email, conversations, etc. If you really want to continue, please continue with the following steps.</p>
      <br />
    </div>
      <div className="flex-column" style={{ padding: 12 }}>
        <InsertEmailStep
          visible={email.length == 0}
          onEmailSubmited={(email) => setEmail(email)}
        />
        <DeleteAccountCodeVerifyStep
          visible={email.length > 0}
          email={email}
          onSuccess={() => { setEmail("") }}
        />
      </div>
    </>
  );
};

type InsertEmailStepProps = {
  onEmailSubmited: (email: string) => void;
  visible: boolean;
};

const InsertEmailStep = ({
  onEmailSubmited,
  visible,
}: InsertEmailStepProps) => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data: string) => { onEmailSubmited(data) };
  const [loading, setIsLoading] = useState(false);
  return (
    <>
      <form
        style={{ visibility: visible ? "visible" : "hidden" }}
        onSubmit={handleSubmit((data) => {
          setIsLoading(true)
          generateCode(data.email)
            .then(() => {
              alert("We have sent a confirmation code to your email. Please check your email and provide us with the confirmation code to proceed with deleting your account.")
              onSubmit(data.email)
            })
            .catch((err) => {
              alert(err.response?.data?.message ? err.response?.data?.message : "Unknown Error")
            })
            .finally(() => setIsLoading(false))
        })}
      >
        <label>
          E-mail
          <input {...register("email")} name="email" />
        </label>
        <label>
          <input
            style={{ visibility: !loading && visible ? "visible" : "hidden" }}
            type="submit"
            value="Next step"
          />
          <progress
            style={{ visibility: loading && visible ? "visible" : "hidden" }}
          />
        </label>
      </form>
    </>
  );
};

type DeleteAccountCodeVerifyStepProps = {
  email: string;
  onSuccess: () => void;
  visible: boolean;
};
const DeleteAccountCodeVerifyStep = ({
  email,
  onSuccess,
  visible,
}: DeleteAccountCodeVerifyStepProps) => {
  const { register, handleSubmit } = useForm();
  const [loading, setIsLoading] = useState(false);
  const onSubmit = (data: string) => {
    setIsLoading(true)
    confirmCode(email, data)
      .then(() => {
        alert(`Deleted account email: ${email}`)
        onSuccess()
      })
      .catch((err) => alert(err.response?.data?.message ? err.response?.data?.message : "Unknown Error"))
      .finally(() => setIsLoading(false))
  };

  return (
    <form
      style={{ visibility: visible ? "visible" : "hidden" }}
      onSubmit={handleSubmit((data) => onSubmit(data.code))}
    >
      <label>
        Código de confirmação
        <input {...register("code")} name="code" />
      </label>
      <label>
        <input
          style={{ visibility: !loading && visible ? "visible" : "hidden" }}
          type="submit"
          value="Delete account"
        />
        <progress
          style={{ visibility: loading && visible ? "visible" : "hidden" }}
        />
      </label>
    </form>
  );
};

export default DeleteAccount;
