import axios from "axios"

const apiUrl = axios.create({
    baseURL: "https://api.plustrend.app",
    timeout: 60000
})

export async function generateCode(email: string) {
    return await apiUrl.post("web-delete-account", null, {
        params: {
            email: email,
        },
    })
}

export async function confirmCode(email: string, code: string) {
    return await apiUrl.post("web-delete-account/confirm", null, {
        params: {
            email: email,
            code: code
        }
    })
}