import { BrowserRouter, Route, Routes } from "react-router-dom"
import Auth from "./pages/Auth"
import Home from "./pages/Home"
import DeleteAccount from "./pages/DeleteAccount"
import TermsOfUse from "./pages/TermsOfUse"

const ProjectRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/auth" element={<Auth />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
            </Routes>
        </BrowserRouter>
    )
}

export default ProjectRoutes